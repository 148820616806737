import React, { useState } from "react";
import "../styles/components/memeUploadModal.css";
import { postMeme } from "../services/memeBaseServiceApi";
import { FiUploadCloud, FiX } from "react-icons/fi";
import LoaderComponent from "./LoaderComponent";
import heic2any from "heic2any";

const MemeUploadModalComponent = ({ isOpen, onClose, onPost }) => {
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [file, setFile] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [converting, setConverting] = useState(false);
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/heic",
    "video/mp4",
    "video/hevc",
    "video/h265",
    "video/HEVC",
    "video/H265",
    "video/quicktime",
  ];
  const allowedExtensions = [
    ".jpeg",
    ".jpg",
    ".png",
    ".heic",
    ".mp4",
    ".hevc",
    ".h265",
    ".mov",
  ];
  const maxFileSize = 30 * 1024 * 1024; // 30 MB in bytes

  const handleFileDrop = async (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      await validateAndSetFile(droppedFile);
      const fileInput = document.getElementById("file");
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(droppedFile);
      fileInput.files = dataTransfer.files;
    }
  };

  const handleFileInputChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      await validateAndSetFile(selectedFile);
    }
  };

  const validateAndSetFile = async (file) => {
    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();

    console.log(`File type: ${file.type}`);
    console.log(`File extension: ${fileExtension}`);

    if (file.size > maxFileSize) {
      setErrorMessages(["Arquivo não pode ser maior que 30MB."]);
      return;
    }

    if (
      file &&
      (allowedTypes.includes(file.type) ||
        allowedExtensions.includes(fileExtension))
    ) {
      if (
        file.type.startsWith("video/") &&
        (allowedTypes.includes(file.type) ||
          allowedExtensions.includes(fileExtension))
      ) {
        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);
          if (video.duration > 120) {
            setErrorMessages(["Vídeos não podem ter mais de 2 minutos."]);
          } else {
            setFile(file);
            setErrorMessages([]);
          }
        };

        video.onerror = () => {
          setErrorMessages(["Não foi possível carregar o vídeo."]);
        };

        video.src = URL.createObjectURL(file);
      } else if (
        file.type === "image/heic" ||
        file.name.toLowerCase().endsWith(".heic")
      ) {
        setConverting(true);
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          const convertedFile = new File(
            [convertedBlob],
            file.name.replace(/\.heic$/, ".jpg"),
            { type: "image/jpeg" }
          );
          setFile(convertedFile);
          setErrorMessages([]);
        } catch (error) {
          setErrorMessages(["Falha ao converter imagem HEIC."]);
        } finally {
          setConverting(false);
        }
      } else {
        setFile(file);
        setErrorMessages([]);
      }
    } else {
      setErrorMessages([
        "Apenas arquivos de imagem (JPEG, PNG, HEIC) ou vídeo (MP4, HEVC, MOV) são permitidos.",
      ]);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    const fileInput = document.getElementById("file");
    fileInput.value = "";
  };

  const handleUploadClick = () => {
    document.getElementById("file").click();
  };

  const validateForm = () => {
    const errors = [];

    if (description.length < 3) {
      errors.push("Descrição deve ter pelo menos 3 caracteres.");
    }

    if (description.length > 200) {
      errors.push("Descrição não pode ter mais de 200 caracteres.");
    }

    const hashtagsArray = hashtags
      .trim()
      .split(" ")
      .filter((tag) => tag.length > 0);
    if (
      hashtagsArray.length === 0 ||
      hashtagsArray.some((tag) => !tag.startsWith("#"))
    ) {
      errors.push("Insira pelo menos uma hashtag válida (#).");
    }

    if (hashtagsArray.length > 5) {
      errors.push("Insira no máximo 5 hashtags.");
    }

    const uniqueHashtags = new Set(hashtagsArray);
    if (uniqueHashtags.size !== hashtagsArray.length) {
      errors.push("Não repita hashtags.");
    }
    if (!file) {
      errors.push("Selecione um arquivo para postar.");
    }

    setErrorMessages(errors);
    return errors.length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setErrorMessages([]);
      try {
        const successMessage = await postMeme(
          description,
          hashtags.split(" "),
          file
        );
        alert(successMessage);
        onPost();
        setDescription("");
        setHashtags("");
        setFile(null);
        onClose();
      } catch (error) {
        const serverErrors = error.message.split(";").map((msg) => msg.trim());
        setErrorMessages(serverErrors);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          <FiX />
        </span>
        <h2>Postar novo meme</h2>
        {loading ? (
          <LoaderComponent loadingMessage="Postando..." />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="description">Descrição:</label>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="3"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="hashtags">Hashtags:</label>
              <input
                type="text"
                id="hashtags"
                name="hashtags"
                value={hashtags}
                onChange={(e) => setHashtags(e.target.value)}
                required
              />
            </div>
            {errorMessages.length > 0 && (
              <ul className="error-messages">
                {errorMessages.map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            )}
            <div
              className="file-upload"
              onClick={handleUploadClick}
              onDrop={handleFileDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <FiUploadCloud className="upload-icon" />
              <p>
                Clique para escolher imagem/video ou arraste se estiver num
                computador
              </p>
              <input
                type="file"
                id="file"
                name="file"
                accept="image/jpeg,image/png,image/heic,video/mp4,video/hevc,video/h265,video/quicktime"
                onChange={handleFileInputChange}
                style={{ display: "none" }}
                required
              />
            </div>
            {converting && <LoaderComponent loadingMessage="Convertendo..." />}
            {file && (
              <div className="file-list">
                <div className="file-item">
                  <span>{file.name}</span>
                  <FiX
                    className="remove-file-icon"
                    onClick={handleRemoveFile}
                  />
                </div>
              </div>
            )}
            {!converting && (
              <button type="submit" className="submit-button">
                Postar
              </button>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default MemeUploadModalComponent;

