import React from "react";
import "../styles/components/placeholder.css";

const PlaceholderComponent = () => {
  return (
    <div className="gallery-item placeholder">
      <div className="gallery-image placeholder-image"></div>
      <div className="gallery-item-overlay">
        <p className="placeholder-text"></p>
        <p className="placeholder-text"></p>
      </div>
    </div>
  );
};

export default PlaceholderComponent;
