import React from "react";
import "../styles/components/conditionalWarning.css";

const ConditionalWarning = ({ title, icon: IconComponent , color}) => {
  return (
    <div className="conditional-warning-container" style={{color:color}}>
      {IconComponent && <IconComponent className="warning-icon" />}
      <p>{title}</p>
    </div>
  );
};

export default ConditionalWarning;
