import React from "react";
import "../styles/components/button.css";

const ButtonComponent = ({ title, onClick }) => {
  const handleTouchStart = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className="custom-button"
      onClick={onClick}
      onTouchStart={handleTouchStart}
    >
      {title}
    </button>
  );
};

export default ButtonComponent;
