import React, { useEffect, useRef, useState } from "react";
import { FiDownload, FiX } from "react-icons/fi";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import {
  getVideoStreamUrl,
  downloadMedia,
} from "../services/memeBaseServiceApi";
import LoaderComponent from "./LoaderComponent"; 
import "../styles/components/mediaDisplayModal.css";

const MediaDisplayModalComponent = ({ isOpen, onClose, media }) => {
  const { src, type, description, hashtags, id } = media;
  const videoRef = useRef(null);
  const [downloading, setDownloading] = useState(false);
  const shareUrl = `https://mozmemes.com?description=${encodeURIComponent(
    description
  )}`;

  useEffect(() => {
    if (isOpen && type === "video" && videoRef.current) {
      const videoElement = videoRef.current;
      videoElement.src = getVideoStreamUrl(id);
      videoElement.load();
      videoElement.play().catch(error => {
        console.error("Error playing video:", error);
      });
    }
  }, [isOpen, type, id]);

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.src = "";
      }
    };
  }, []);

  const handleDownload = async () => {
    try {
      setDownloading(true); 
      await downloadMedia(media);
    } catch (error) {
      console.error("Error downloading media:", error);
    } finally {
      setDownloading(false); 
    }
  };

  const handleCloseModal = () => {
    onClose();
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
    }
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content media-modal">
        <span className="close" onClick={handleCloseModal}>
          <FiX />
        </span>
        <div className="media-container">
          {type === "image" && (
            <img src={src} alt="Media" className="media-image" />
          )}
          {type === "video" && (
            <video controls ref={videoRef} className="media-video">
              <source type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
        <div className="media-details">
          <h2>Descrição:</h2>
          <p>{description}</p>
          <h2>Hashtags:</h2>
          <p>{hashtags}</p>
        </div>
        <div className="media-actions">
          <div className={`buttons-container ${downloading ? "hidden" : ""}`}>
            <button className="icon-button download" onClick={handleDownload}>
              <FiDownload size={32} />
            </button>
            <WhatsappShareButton url={shareUrl} title={description}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <FacebookShareButton
              url={shareUrl}
              quote={description}
              hashtag={hashtags}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TelegramShareButton url={shareUrl} title={description}>
              <TelegramIcon size={32} round />
            </TelegramShareButton>
          </div>
          {downloading && (
            <div className="downloading-container">
              <LoaderComponent loadingMessage="Preparando o download..." />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaDisplayModalComponent;

