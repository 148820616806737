import * as React from "react";
import { createRoot } from "react-dom/client";
import
{
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Feed from "./screens/Feed";
import About from "./screens/About";
import Subscribe from "./screens/Subscribe";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Feed />,
  },
  {
    path: "/about",
    element: <About />,
  }, {
    path: "/subscribe",
    element: <Subscribe />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
