import React, { useState } from "react";
import "../styles/global.css";
import "../styles/pages/subscribe.css";
import HeaderComponent from "../components/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import { subscribeUserEmail } from "../services/memeBaseServiceApi";
import LoaderComponent from "../components/LoaderComponent";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setMessageType("error");
      setMessage("Por favor, insira um endereço de e-mail válido.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const result = await subscribeUserEmail(email);
      setLoading(false);
      setMessageType("success");
      setMessage(result);
      alert(result); 
      window.location.href = "/"; 
    } catch (error) {
      setLoading(false);
      setMessageType("error");
      setMessage(error.message || "Erro ao se inscrever.");
    }
  };

  return (
    <main>
      <header>
        <HeaderComponent />
      </header>
      <div className="logo-container">
        <img src="/images/app_logo.png" alt="logo" className="app-logo" />
      </div>
      <div className="subscribe-outer-container">
        <div className="subscribe-container">
          <h1 className="subscribe-title">
            Quer ser notificado sobre os memes mais quentes do momento?
          </h1>
          <p className="subscribe-text">
            No futuro, pretendemos implementar uma funcionalidade que lhe
            enviará o link dos memes mais pesquisados, baixados e compartilhados
            nesta plataforma. Se você quer estar na lista de notificações, deixe
            seu email abaixo!
          </p>
          <input
            type="email"
            className="subscribe-input"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {loading ? (
            <LoaderComponent loadingMessage="Inscrevendo..." />
          ) : (
            <button className="subscribe-button" onClick={handleSubmit}>
              Inscrever-se
            </button>
          )}
          {message && (
            <p className={`subscribe-message ${messageType}`}>{message}</p>
          )}
        </div>
      </div>
      <footer>
        <FooterComponent />
      </footer>
    </main>
  );
};

export default Subscribe;
