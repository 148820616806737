const BASE_URL = process.env.REACT_APP_API_URL || "https://mozmemes.com";

const checkResponse = async (response) => {
    if (response.status === 204) {
        return [];
    }
    if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
    }
    return response.json();
};

export const postMeme = async (description, hashtags, file) => {
    const formData = new FormData();
    formData.append("description", description);
    hashtags.forEach(tag => {
        formData.append("hashtags[]", tag);
    });
    formData.append("content", file);

    let endpoint;
    if (file.type.startsWith("image")) {
        endpoint = `${BASE_URL}/api/service/image`;
    } else if (file.type.startsWith("video")) {
        endpoint = `${BASE_URL}/api/service/video`;
    } else {
        throw new Error(`Unsupported file type: ${file.type}`);
    }

    const response = await fetch(endpoint, {
        method: "POST",
        body: formData,
    });

    if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to post meme");
        } else {
            const errorMessage = await response.text();
            throw new Error(errorMessage);
        }
    }

    return response.text();
};

export const searchByDescription = async (description) => {
    const videoResponse = await fetch(`${BASE_URL}/api/service/videos/bydescription`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ description }),
    });

    const imageResponse = await fetch(`${BASE_URL}/api/service/images/bydescription`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ description }),
    });

    const videos = await checkResponse(videoResponse);
    const images = await checkResponse(imageResponse);

    return { videos, images };
};

export const searchByHashtags = async (hashtags) => {
    const videoResponse = await fetch(`${BASE_URL}/api/service/videos/byhashtags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ hashtags }),
    });

    const imageResponse = await fetch(`${BASE_URL}/api/service/images/byhashtags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ hashtags }),
    });

    const videos = await checkResponse(videoResponse);
    const images = await checkResponse(imageResponse);

    return { videos, images };
};

export const getVideoStreamUrl = (id) => {
    return `${BASE_URL}/api/service/videos/stream?id=${id}`;
};

export const loadGalleryItems = async (skip) => {
    const videoResponse = await fetch(`${BASE_URL}/api/service/videos?skip=${skip}`);
    const imageResponse = await fetch(`${BASE_URL}/api/service/images?skip=${skip}`);

    if (!videoResponse.ok || !imageResponse.ok) {
        throw new Error("Failed to fetch gallery items");
    }

    const videoData = await videoResponse.json();
    const imageData = await imageResponse.json();

    const videos = (videoData || []).map(video => ({
        id: video.id,
        src: `data:image/jpeg;base64,${video.path}`,
        type: "video",
        description: video.description,
        hashtags: video.hashtags.join(" "),
    }));

    const images = (imageData || []).map(image => ({
        id: image.id,
        src: `data:image/jpeg;base64,${image.imageData}`,
        type: "image",
        description: image.description,
        hashtags: image.hashtags.join(" "),
    }));

    return { videos, images };
};

export const downloadMedia = async (media) => {
    if (media.type === "video") {
        const response = await fetch(`${BASE_URL}/api/service/videos/download?id=${media.id}`);
        if (!response.ok) {
            throw new Error("Failed to download video");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${media.description}.mp4`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    } else {
        const link = document.createElement("a");
        link.href = media.src;
        link.download = media.description;
        link.click();
    }
};

export const subscribeUserEmail = async (email) => {
    const response = await fetch(`${BASE_URL}/api/service/user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || "Failed to subscribe.");
    }

    return data.message;
};

