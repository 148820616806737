import React from "react";
import { MoonLoader } from "react-spinners";
import "../styles/components/loader.css";

const LoaderComponent = ({ loadingMessage }) => {
  return (
    <div className="loader-container">
      <div className="loading-message">{loadingMessage}</div>
      <div className="spinner-container">
        <MoonLoader color="#4A90E2" loading={true} size={50} />
      </div>
    </div>
  );
};

export default LoaderComponent;
