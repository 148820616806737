import React, { useState, useEffect } from "react";
import "../styles/components/searchBar.css";
import Select from "react-select";
import { FaSearch } from "react-icons/fa";
import {
  searchByDescription,
  searchByHashtags,
} from "../services/memeBaseServiceApi";

const SearchBar = ({
  onSearchResults,
  onClearSearch,
  initialDescription,
  isError,
  clearSearch,
  setLoading, 
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const options = [
    { value: "option1", label: "Pesquisa por #hashtags" },
    { value: "option2", label: "Pesquisa por descrição" },
  ];

  useEffect(() => {
    if (initialDescription) {
      setSelectedOption({ value: "option2", label: "Pesquisa por descrição" });
      setSearchQuery(initialDescription);
      handleSearchClick(initialDescription);
    }
  }, [initialDescription]);

  useEffect(() => {
    if (clearSearch) {
      setSelectedOption(null);
      setSearchQuery("");
      setErrorMessage("");
      onClearSearch();
    }
  }, [clearSearch, onClearSearch]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setSearchQuery("");
    setErrorMessage("");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setErrorMessage("");
  };

  const validateSearch = () => {
    if (!selectedOption) {
      setErrorMessage("Selecione um tipo de pesquisa!");
      return false;
    }

    if (selectedOption.value === "option2" && searchQuery.length < 3) {
      setErrorMessage("Descrição deve ter pelo menos 3 caracteres.");
      return false;
    }

    if (selectedOption.value === "option1") {
      const hashtags = searchQuery.trim().split(" ");
      if (
        hashtags.length === 0 ||
        hashtags.some((tag) => !tag.startsWith("#"))
      ) {
        setErrorMessage("Insira pelo menos uma hashtag válida (#).");
        return false;
      }
    }

    setErrorMessage("");
    return true;
  };

  const handleSearchClick = async (searchText = searchQuery) => {
    if (validateSearch())
    {
      setLoading(true);

      try {
        let results;
        if (selectedOption.value === "option2") {
          results = await searchByDescription(searchText);
        } else if (selectedOption.value === "option1") {
          const hashtags = searchQuery.trim().split(" ");
          results = await searchByHashtags(hashtags);
        }
        onSearchResults(results);
      } catch (error) {
        setErrorMessage("Erro ao buscar resultados: " + error.message);
      } finally {
        setLoading(false); 
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && document.activeElement === event.target) {
      handleSearchClick();
    }
  };

  return (
    <div className="select-search-component">
      <Select
        className="select-dropdown"
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        placeholder="Selecione um tipo de pesquisa!"
        isClearable={false}
        isSearchable={false}
      />
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress}
          placeholder="Pesquisa..."
          disabled={!selectedOption || isError}
        />
        <FaSearch
          className={`search-icon ${isError ? "disabled" : ""}`}
          onClick={!isError ? () => handleSearchClick() : null}
        />
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default SearchBar;

