import React, { useState, useEffect, useRef } from "react";
import "../styles/global.css";
import HeaderComponent from "../components/HeaderComponent";
import SearchBarComponent from "../components/SearchBarComponent";
import GalleryComponent from "../components/GalleryComponent";
import FloatingButtonComponent from "../components/FloatingButtonComponent";
import ButtonComponent from "../components/ButtonComponent";
import MemeUploadModalComponent from "../components/MemeUploadModalComponent";
import MediaDisplayModalComponent from "../components/MediaDisplayModalComponent";
import LoaderComponent from "../components/LoaderComponent";
import {
  loadGalleryItems,
  searchByDescription,
} from "../services/memeBaseServiceApi";
import { useSearchParams, useNavigate } from "react-router-dom";
import { LuServerOff } from "react-icons/lu";
import { TfiFaceSad } from "react-icons/tfi";
import { RiEmotionHappyLine } from "react-icons/ri";
import ConditionalWarning from "../components/ConditionalWarning";
import FooterComponent from "../components/FooterComponent";

const Feed = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [descriptionParam, setDescriptionParam] = useState(null);
  const [error, setError] = useState(false);
  const [noContentFound, setNoContentFound] = useState(false);
  const [areAllMemesLoaded, setAreAllMemesLoaded] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const galleryRef = useRef(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const description = searchParams.get("description");
    if (description) {
      setDescriptionParam(description);
    } else {
      loadItems(0, true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (descriptionParam) {
      handleSearchResults({  videos: [], images: [], }, descriptionParam);
    }
  }, [descriptionParam]);

  const loadItems = async (skipValue, reset = false) => {
    if (reset) {
      setGalleryItems([]);
      setSkip(0);
      setAreAllMemesLoaded(false);
    }

    if (skipValue === 0) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    setLoadingMessage("Buscando os memes da base de dados...");
    setError(false);
    try {
      const { videos, images } = await loadGalleryItems(skipValue);

      if (  videos.length === 0 && images.length === 0) {
        setAreAllMemesLoaded(true);
      } else {
        setGalleryItems((prevItems) => [...prevItems, ...videos, ...images]);
        setSkip((prevSkip) => prevSkip + 3);
      }
    } catch (error) {
      console.error("Error fetching gallery items:", error);
      setError(true);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

const handleSearchResults = async (results, description) => {
  setLoading(true);
  setLoadingMessage("Searching for memes...");
  setError(false);
  setAreAllMemesLoaded(false);
  setGalleryItems([]);
  try {
    const searchResults = description
      ? await searchByDescription(description)
      : results;
    const { videos, images } = searchResults;

       const formattedVideos = (videos || []).map((video) => ({
         id: video.id,
         src: `data:image/jpeg;base64,${video.path}`,
         type: "video",
         description: video.description,
         hashtags: video.hashtags.join(" "),
       }));
    
    const formattedImages = (images || []).map((img) => ({
      id: img.id,
      src: `data:image/jpeg;base64,${img.imageData}`,
      type: "image",
      description: img.description,
      hashtags: img.hashtags.join(" "),
    }));

 

    if (formattedVideos.length === 0 && formattedImages.length === 0) {
      setNoContentFound(true);
    } else {
      setNoContentFound(false);
      setGalleryItems([...formattedVideos, ...formattedImages]);
    }
    setIsSearchActive(true);
  } catch (error) {
    console.error("Error processing search results:", error);
    setError(true);
  } finally {
    setLoading(false);
  }
};


  const handleReturnToFeed = async () => {
    setIsSearchActive(false);
    setNoContentFound(false);
    setGalleryItems([]);
    setSkip(0);
    setLoading(true);
    setAreAllMemesLoaded(false);
    setClearSearch(true);

    try {
      await loadItems(0, true);
      navigate("/");
    } catch (error) {
      console.error("Error loading gallery items:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = async () => {
    setModalOpen(false);
  };

  const resetGalleryOnMemePost = async () => {
    setGalleryItems([]);
    setSkip(0);
    await loadItems(0, true);
  };

  const openMediaModal = (media) => {
    setSelectedMedia(media);
    setMediaModalOpen(true);
  };

  const closeMediaModal = () => {
    setMediaModalOpen(false);
  };

  const handleLoadMoreClick = async () => {
    await loadItems(skip);

    const currentScrollPosition = window.pageYOffset;
    const offset = 150;
    const newScrollPosition = currentScrollPosition + offset;

    window.scrollTo({
      top: newScrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <main ref={galleryRef}>
      <header>
        <HeaderComponent />
      </header>
      <div className="logo-container">
        <img src="/images/app_logo.png" alt="logo" className="app-logo" />
      </div>
      <SearchBarComponent
        onSearchResults={handleSearchResults}
        onClearSearch={() => setClearSearch(false)}
        initialDescription={descriptionParam}
        isError={error}
        clearSearch={clearSearch}
        setLoading={setLoading} 
      />

      {loading && <LoaderComponent loadingMessage={loadingMessage} />}

      {!loading && error && (
        <ConditionalWarning
          title="O servidor está embaixo, por favor aguarde e tente de novo mais tarde ou contacte o desenvolvedor."
          icon={LuServerOff}
        />
      )}

      {!loading && !error && (
        <>
          {noContentFound ? (
            <>
              <ConditionalWarning
                title="Nada foi encontrado na base de dados."
                icon={TfiFaceSad}
              />
              <ButtonComponent
                title={"Retornar ao feed"}
                onClick={handleReturnToFeed}
              />
            </>
          ) : (
            <>
              <GalleryComponent
                items={galleryItems}
                onItemClick={openMediaModal}
                loadingMore={loadingMore}
              />
              <FloatingButtonComponent onClick={openModal} />
              {isSearchActive ? (
                <ButtonComponent
                  title={"Retornar ao feed"}
                  onClick={handleReturnToFeed}
                />
              ) : (
                !areAllMemesLoaded && (
                  <ButtonComponent
                    title={"Mais memes maus!"}
                    onClick={handleLoadMoreClick}
                    disabled={loading}
                  />
                )
              )}
              <MemeUploadModalComponent
                isOpen={modalOpen}
                onClose={closeModal}
                onPost={resetGalleryOnMemePost}
              />
              {selectedMedia && (
                <MediaDisplayModalComponent
                  isOpen={mediaModalOpen}
                  onClose={closeMediaModal}
                  media={selectedMedia}
                />
              )}
            </>
          )}
        </>
      )}

      {areAllMemesLoaded && (
        <ConditionalWarning
          title="Todos os memes ja estao renderizados."
          icon={RiEmotionHappyLine}
          color={"green"}
        />
      )}
      <footer>
        <FooterComponent />
      </footer>
    </main>
  );
};

export default Feed;

