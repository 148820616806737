import React, { useEffect } from "react";
import "../styles/components/gallery.css";
import PlaceholderComponent from "./PlaceholderComponent";

const GalleryComponent = ({ items, onItemClick, loadingMore }) => {

  return (
    <div className="gallery">
      {items.map((item, index) => (
        <div
          key={item.id}
          className="gallery-item"
          onClick={() => onItemClick(item)}
          style={{ "--animation-order": index }}
        >
          {item.type === "image" || item.type === "video" ? (
            <img
              src={item.src}
              alt={item.description}
              className="gallery-image"
              loading="lazy" 
            />
          ) : null}
          <div className="gallery-item-overlay">
            <p className="gallery-item-description">{item.description}</p>
            <p className="gallery-item-hashtags">{item.hashtags}</p>
          </div>
        </div>
      ))}
      {loadingMore &&
        Array.from({ length: 3 }).map((_, index) => (
          <PlaceholderComponent key={`placeholder-${index}`} />
        ))}
    </div>
  );
};

export default GalleryComponent;
