import React from "react";
import "../styles/global.css";
import "../styles/pages/about.css";
import FooterComponent from "../components/FooterComponent";

import HeaderComponent from "../components/HeaderComponent";

export default function About() {
  return (
    <main>
      <header>
        <HeaderComponent />
      </header>
      <div className="logo-container">
        <img src="/images/app_logo.png" alt="logo" className="app-logo" />
      </div>
      <div className="container">
        <section className="about-section">
          <h1>O que é a Moz Memes?</h1>
          <p>
            A Moz Memes é uma aplicação web desenvolvida com o objetivo de
            armazenar os memes preferidos dos Moçambicanos e, principalmente,
            permitir uma <b>pesquisa</b> dos memes de uma maneira extremamente
            fácil.
          </p>
          <p>
            Nesta aplicação, inicialmente foram inseridos pouco mais de 15 memes
            na base de dados que podem ser vistas no feed. Porém, a aplicação
            tem espaço para muito mais. Por isso, esta aplicação permite que
            qualquer pessoa, incluindo você, possa inserir memes nela!
          </p>
          <p>
            Resumindo, você pode pesquisar pelos seus memes preferidos de
            maneira fácil e, se a aplicação não tiver, você pode inseri-los e
            eles estarão sempre disponíveis para visualização.
          </p>
        </section>

        <section className="about-section">
          <h1>Como eu faço a pesquisa e post de memes na aplicação?</h1>
          <p>
            É muito simples! Se preferir aprender com um vídeo explicado pelo
            desenvolvedor como utilizar,{" "}
            <a href="https://youtu.be/CCAfxdJNszM"> clique aqui!</a>
          </p>
          <p>
            Caso contrário, não tem muito que saber. Para pesquisar memes, só
            precisa acessar ao <a href="/">feed</a> e você verá uma barra de
            pesquisa. Na aplicação, você pode pesquisar por memes de duas
            formas: por hashtags e por descrição. É importante selecionar o tipo
            de pesquisa na barra de pesquisa!
          </p>
        </section>

        <section className="about-section">
          <h3>Pesquisas por hashtags</h3>
          <p>
            Para quem não sabe, hashtags são palavras antecedidas por um
            cardinal (#), por exemplo, #pfukaubhava. Se pesquisar esta hashtag,
            se memes com hashtags existirem na aplicação, você irá receber esses
            memes. Você pode adicionar várias hashtags em uma pesquisa!
          </p>
        </section>

        <section className="about-section">
          <h3>Pesquisas por descrição</h3>
          <p>
            Por descrição, você simplesmente descreve o meme, por exemplo:
            "Telma nao fala nada".
          </p>
        </section>

        <section className="about-section">
          <h3>Postagem de memes</h3>
          <p>
            Para postagem de memes, é crucial descrever os memes com algumas
            palavras para que outras pessoas consigam encontrar o meme postado.
            Portanto, por favor, ao postar seus memes preferidos, capriche!
          </p>
          <p>
            E não se preocupe em verificar se o meme já existe. Se ele existir,
            o sistema vai detectar e informar que o meme já está presente,
            retornando as hashtags e a descrição do vídeo.
          </p>
        </section>

        <section className="about-section">
          <h3>E se eu encontrar um erro estranho?</h3>
          <p>
            Se encontrar algum erro, por favor, contacte o desenvolvedor e, se
            possível, informe detalhadamente como o erro ocorreu e como
            reproduzir o erro.
          </p>
        </section>

        <section className="about-section">
          <h3>Como esta app foi desenvolvida (Para desenvolvedores)</h3>
          <p>
            Se você está curioso sobre como esta app foi desenvolvida, qual
            stack de tecnologias utilizei,{" "}
            <a href="https://medium.com/@valdompinga57/why-and-how-i-made-a-meme-database-with-an-emphasis-on-mozambican-memes-8c04308c8d34">
              clique neste artigo
            </a>{" "}
            onde está tudo explicado, incluindo o código fonte.
          </p>
        </section>
      </div>
      <footer>
        <FooterComponent />
      </footer>
    </main>
  );
}
