import React from "react";
import "../styles/global.css";
import "../styles/components/footer.css";

function FooterComponent() {
  return (
    <footer className="footer">
      <p>
        &copy; 2024{" "}
        <a
          href="https://www.valdompinga.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Valdo Mpinga
        </a>
      </p>
    </footer>
  );
}

export default FooterComponent;
