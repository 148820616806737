import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/components/header.css";

const HeaderComponent = () => {
  const handleFeedClick =  () => {
  window.location.href = "https://mozmemes.com";
  };

  return (
    <header className="header">
      <nav className="navbar">
        <ul className="nav-list">
          <li className="nav-item">
            <NavLink
              to="/"
              onClick={handleFeedClick}
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              Feed
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              Sobre
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/subscribe"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
            >
              Subscrever
            </NavLink>
          </li>
          <li className="nav-item">
            <a
              href="https://www.valdompinga.com/"
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              O Desenvolvedor
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderComponent;
