import React from "react";
import { FaUpload } from "react-icons/fa";
import "../styles/components/fab.css";

const FloatingButtonComponent = ({ onClick }) => {
  const handleUploadClick = () => {
    onClick(); 
  };

  return (
    <div className="fab" onClick={handleUploadClick}>
      <FaUpload className="fab-icon" />
    </div>
  );
};

export default FloatingButtonComponent;
